import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo3 } from '../img/homepage/logo3.svg';
import { ReactComponent as Logo2 } from '../img/head/logo2.svg';
import { ReactComponent as HomesChecking } from '../img/homepage/HomesCheckin.svg';
import { ReactComponent as Line } from '../img/homepage/line.svg';
import { useHistory } from "react-router";
import '../css/homePage.scss'

const HomePage = (props) =>
{
    const { t } = useTranslation();
    const history = useHistory();
    const [number, setNumber] = useState(0);
    const list = [
        {
            type: "1",
            logo: <HomesChecking className="homepage_c_item_first_logo" />,
            title: t("homePage.title1"),
            content: t("homePage.content1"),
        },
        {
            title: t("homePage.title2"),
            content: t("homePage.content2"),
            about: t("homePage.about2"),
            type: "2",
            list: [
                { title: t("homePage.list2.title1"), content: t("homePage.list2.content1") },
                { title: t("homePage.list2.title3"), content: t("homePage.list2.content3") },
                { title: t("homePage.list2.title2"), content: t("homePage.list2.content2") },
                { title: t("homePage.list2.title4"), content: t("homePage.list2.content4") },
            ],
            imgList: [
                { img: require("../img/homepage/property01.jpg"), tag: t('homePage.img2_title') },
                { img: require("../img/homepage/property02.jpg"), tag: t('homePage.img2_title') },
                { img: require("../img/homepage/property03.jpg"), tag: t('homePage.img2_title') },
                { img: require("../img/homepage/property04.jpg"), tag: t('homePage.img2_title') },
                { img: require("../img/homepage/property05.jpg"), tag: t('homePage.img2_title') },
            ],
            id: "rentals"
        },
        {
            
            title: t("homePage.title3"),
            id: "buyAndSell",
            content: t("homePage.content3"),
            about: t("homePage.about3"),
            type: "3",
            list: [
                { 
                    title: t("homePage.list3.title1"), 
                    title_small: t("homePage.list3.title1_second"), 
                    content: t("homePage.list3.content1"),
                    spanName: "Model Home",
                    imgList: [
                        { title: t("homePage.list3.imgTitle1_1"), img: require('../img/homepage/agreement.jpg') },
                        { title: t("homePage.list3.imgTitle1_2"), img: require('../img/homepage/site_plan.jpg') },
                    ],
                    img: require("../img/homepage/model_home.jpg"),
                },
                { 
                    title: t("homePage.list3.title2"), 
                    title_small: t("homePage.list3.title2_second"), 
                    content: t("homePage.list3.content2"), 
                    spanName: "Florida MLS",
                    imgList: [
                        { title: t("homePage.list3.imgTitle2_1"), img: require('../img/homepage/license.jpg') },
                        { title: t("homePage.list3.imgTitle2_2"), img: require('../img/homepage/HUD.jpg') },
                    ],
                    img: require("../img/homepage/MLS.jpg"),
                },
            ],
        },
        {
            title: t("homePage.title4"),
            content: t("homePage.content4"),
            about: t("homePage.about4"),
            id: "flipping",
            type: "4",
            list: [
                { title: t("homePage.list4.title1"), content: t("homePage.list4.content1") },
                { title: t("homePage.list4.title2"), content: t("homePage.list4.content2") },
                { title: t("homePage.list4.title3"), content: t("homePage.list4.content3") },
            ],
            columns: [
                {   title: "Eff Date", props: "date"  },
                {   title:  "Change Type", props: "type"  },
                {   title: "Change Info",  props: "info" },
                {   title: "Current Price", props: "price"  },
                {   title: "Dom", props: "dom" },
            ],
            softwareList: [
                {
                    title: "After MLS#: O60**793",
                    data: [
                        { date: "05/12/2023", type: "Closed", info: "PND->SLD", price: "$275,000", dom: "10" },
                        { date: "03/28/2023", type: "Pending", info: "ACT->PND", price: "$275,000", dom: "10" },
                        { date: "03/18/2023", type: "New Listing", info: "->ACT", price: "$275,000", dom: "0" },
                    ],
                    imgList: [require('../img/homepage/flipping_o5.jpg'),require('../img/homepage/flipping_o6.jpg'),require('../img/homepage/flipping_o7.jpg'),require('../img/homepage/flipping_o8.jpg'), ]
                },
                {
                    title: "Before MLS#: O60**202",
                    data: [
                        { date: "11/29/2022", type: "Closed", info: "PND->SLD", price: "$150,000", dom: "5" },
                        { date: "11/07/2022", type: "Pending", info: "ACT->PND", price: "$154,900", dom: "7" },
                        { date: "11/02/2022", type: "New Listing", info: "->ACT", price: "$154,900", dom: "0" },
                    ],
                    imgList: [require('../img/homepage/flipping_o1.jpg'),require('../img/homepage/flipping_o2.jpg'),require('../img/homepage/flipping_o3.jpg'),require('../img/homepage/flipping_o4.jpg'), ]
                },
            ],
        },
        {
            title: t('homePage.title5'),
            content: t("homePage.content5"),
            about: t("homePage.about5"),
            id: "development",
            type: "5",
            list: [
                { title: t("homePage.list5.title1"), content: t("homePage.list5.content1") },
                { title: t("homePage.list5.title2"), content: t("homePage.list5.content2") },
                { title: t("homePage.list5.title3"), content: t("homePage.list5.content3") },
                { title: t("homePage.list5.title4"), content: t("homePage.list5.content4") },
            ],
            boxList: [
                { 
                    type: "1",
                    title: t("homePage.content5_list.title1"),
                    content: [t("homePage.content5_list.content1_1"),t("homePage.content5_list.content1_2")]
                },
                
                { 
                    type: "2",
                    title: t("homePage.content5_list.title2"),
                    list: [
                        { label: t("homePage.content5_list.content_list.label1"), content: t("homePage.content5_list.content_list.content1") },
                        { label: t("homePage.content5_list.content_list.label2"), content: t("homePage.content5_list.content_list.content2") },
                        { label: t("homePage.content5_list.content_list.label3"), content: t("homePage.content5_list.content_list.content3") },
                        { label: t("homePage.content5_list.content_list.label4"), content: t("homePage.content5_list.content_list.content4") },
                        { label: t("homePage.content5_list.content_list.label5"), content: t("homePage.content5_list.content_list.content5") },
                        { label: t("homePage.content5_list.content_list.label6"), content: t("homePage.content5_list.content_list.content6") },
                        { label: t("homePage.content5_list.content_list.label7"), content: t("homePage.content5_list.content_list.content7") },
                        { label: t("homePage.content5_list.content_list.label8"), content: t("homePage.content5_list.content_list.content8") },
                        { label: t("homePage.content5_list.content_list.label9"), content: t("homePage.content5_list.content_list.content9") },
                        { label: t("homePage.content5_list.content_list.label10"), content: t("homePage.content5_list.content_list.content10") },
                        { label: t("homePage.content5_list.content_list.label11"), content: t("homePage.content5_list.content_list.content11") },
                        { label: t("homePage.content5_list.content_list.label12"), content: t("homePage.content5_list.content_list.content12") },
                        { label: t("homePage.content5_list.content_list.label13"), content: t("homePage.content5_list.content_list.content13") },
                        { label: t("homePage.content5_list.content_list.label14"), content: t("homePage.content5_list.content_list.content14") },
                    ],
                    content: t("homePage.content5_list.introduce"),
                    imgList: [require('../img/homepage/bv3_01.jpg'), require('../img/homepage/bv3_02.jpg'), require('../img/homepage/bv3_03.jpg'), require('../img/homepage/bv3_04.jpg'),],
                },
                {
                    type: "3",
                    title: t("homePage.content5_list.title3"),
                    list: [
                        { img: require('../img/homepage/grandpine.jpg'), title: t("homePage.content5_list.title3_1") },
                        { img: require('../img/homepage/watersong.jpg'), title: t("homePage.content5_list.title3_2") },
                        { img: require('../img/homepage/ariana.jpg'), title: t("homePage.content5_list.title3_3") },
                    ]
                }
            ]
        },
    ];

    const turnToDocument = (data) =>
    {
        history.push({
            pathname: `/${t('langPath')}/documentation`,
        });
        setTimeout(() => {
            let e = document.getElementById(data.id);
            if (e) {
                window.scrollTo({
                    behavior: 'smooth',
                    top: e.offsetTop + 550
                })
            }
        })
    }

    useEffect(() => {
        document.title = t('tabTitle.title1')
    }, [])
    
    return <>
        <div className="homepage_box">
            <div className="homepage_top_img_box" style={{ height: props.state.isShowAdvert ? 'calc(100vh - 36px)' : '100vh' }}>
                <div className="homepage_top_img_center_box">
                    <Logo3 />
                </div>
                <div className="homepage_top_img_bottom_box">
                    <Logo2 className="homepage_top_img_bottom_box_svg" />
                    <div className="homepage_top_img_bottom_text">©HomesCheckin</div>
                    <div className="homepage_top_img_bottom_box_line"></div>
                </div>
            </div>
            <div className="homepage_container">
                {
                    list.map((item, index) => {
                        if (item.type === '1')
                        {
                            return <div className="homepage_c_item homepage_c_item_first" key={index}>
                                {item.logo}
                                <div 
                                    className="homepage_c_item_title" 
                                    id={item.id} 
                                    // dangerouslySetInnerHTML={{ __html:  }} 
                                    style={{ position: "relative" }}
                                >
                                    <Line style={{ position: "absolute", right:  t('lang') == 'CN'? "20px" : "0", bottom: '-15px', width: t('lang') == 'CN'? "250px" : "280px" }} />
                                    {item.title}
                                </div>
                                <div className="homepage_c_item_article">{item.content}</div>
                            </div>
                        } else {
                            return (
                                <div key={index} className="homepage_c_item">
                                    <div className="homepage_c_item_title colorful-text" id={item.id}>{item.title}</div>
                                    <div className="homepage_c_item_content_box">
                                        <div className="homepage_c_item_article">{item.content}</div>
                                        <div className="homepage_c_item_content_more_btn" onClick={() => turnToDocument(item)}>
                                            <div className="homepage_c_item_content_more_btn_title_big">{t('homePage.moreBtn')}</div>
                                            <div className="homepage_c_item_content_more_btn_title_small">{item.about}</div>
                                        </div>
                                    </div>
                                    {
                                        item.type === '2'
                                        ?   <>
                                                <div className="homepage_c_item_type2_item1">
                                                    {
                                                        item.list.map((cItem, cIndex) => {
                                                            return (
                                                                <div className="homepage_c_item_type2_item1_item" key={cIndex}>
                                                                    <div className="homepage_c_item_type2_item1_item_title">
                                                                        {cItem.title}
                                                                    </div>
                                                                    <div className="homepage_c_item_type2_item1_item_content">
                                                                        {cItem.content}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className="homepage_c_item_type2_item2_wrap">
                                                    <div className="homepage_c_item_type2_item2">
                                                        <ul 
                                                            className="homepage_c_item_type2_item2_Box" 
                                                            style={{ 
                                                                width: (item.imgList.length * 400 + (item.imgList.length - 1) * 40) + 'px',
                                                                left: number * -(400 + 40) + 'px'
                                                            }}
                                                        >
                                                            {
                                                                item.imgList.map((cItem, cIndex) => {
                                                                    return <li key={cIndex}>
                                                                        <div className="homepage_c_item_type2_item2_tag">Guest Favorite</div>
                                                                        <img src={cItem.img} alt="" />
                                                                    </li>
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div className="homePage_type2_btn_box">
                                                        <div className={`homePage_type2_btn homePage_type2_btn_left ${number > 0 ? "" : "cursor_no-drop"}`} onClick={() => {
                                                                if (number > 0) {
                                                                    setNumber(number - 1)
                                                                }
                                                            }}>
                                                            <span class="iconfont icon-zuojiantou-"></span>
                                                        </div>
                                                        <div className={`homePage_type2_btn homePage_type2_btn_right ${number < item.imgList.length - 3 ? "" : "cursor_no-drop"}`} onClick={() => {
                                                                if (number < item.imgList.length - 3) {
                                                                    setNumber(number + 1)
                                                                }
                                                            }}>
                                                            <span class="iconfont icon-youjiantou1" ></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        :   <></>
                                    }
                                    {
                                        item.type === '3'
                                        ?   <>
                                                <div className="homepage_c_item_type2">
                                                    {
                                                        item.list.map((cItem, cIndex) => {
                                                            return <div className="homepage_c_item_type2_item" key={cIndex}>
                                                                <div className="homepage_c_item_type2_item_title">{cItem.title}</div>
                                                                <div className="homepage_c_item_type2_item_title homepage_c_item_type2_item_title_small">{cItem.title_small}</div>
                                                                <div className="homepage_c_item_type2_item_content">{cItem.content}</div>
                                                                <div className="homepage_c_item_type2_item_imglist">
                                                                    {
                                                                        cItem.imgList.map((gItem, gIndex) => {
                                                                            return <div key={gIndex} className="homepage_c_item_type2_item_imglist_box">
                                                                                <div className="homepage_c_item_type2_item_imglist_title">
                                                                                    { gItem.title }
                                                                                </div>
                                                                                <img src={gItem.img} alt="" />
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                                <div style={{ position: "relative" }}>
                                                                    <div className="homepage_c_item_type2_item_imglist_tag">{cItem.spanName}</div>
                                                                    <img src={cItem.img} alt="" className="homepage_c_item_type2_item_img" />
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </>
                                        :   <></>
                                    }
                                    {
                                        item.type === '4'
                                        ?   <>
                                                <div className="homepage_c_item_type3">
                                                    <div className="homepage_c_item_type3_left">
                                                        {
                                                            item.list.map((cItem, cIndex) => {
                                                                return <div key={cIndex}>
                                                                    <div className="homepage_c_item_type3_left_title">{cItem.title}</div>
                                                                    <div className="homepage_c_item_type3_left_content">{cItem.content}</div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                    <div className="homepage_c_item_type3_right">
                                                        {
                                                            item.softwareList.map((cItem, cIndex) => {
                                                                return <div className="homepage_c_item_type3_right_item" key={cIndex}>
                                                                    <div className="homepage_c_item_type3_right_item_title">{cItem.title}</div>
                                                                    <div className="homepage_c_item_type3_right_item_table_title">
                                                                        {
                                                                            item.columns.map((gItem, gIndex) => {
                                                                                return <div className={`homepage_c_item_type3_right_item_table_title_item ${gIndex > 2 ? "homepage_type3_table_title_right" : ""}`} key={gIndex}>
                                                                                    {gItem.title}
                                                                                </div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                    <div className="homepage_3_line"></div>
                                                                    <div className="homepage_3_data_box">
                                                                        {
                                                                            cItem.data.map((gItem, gIndex) => {
                                                                                return <div className="homepage_3_data_item" key={gIndex}>
                                                                                    {
                                                                                        item.columns.map((sItem, sIndex) => {
                                                                                            return <div className={`homepage_3_tableData_data ${sIndex > 2 ? "homepage_3_tableData_data_right" : ""}`} key={sIndex}>
                                                                                                {gItem[sItem.props]}
                                                                                            </div>
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                    
                                                                    <div className={`homepage_3_img_box ${cIndex === 0 ? "mb-40" : ""}`}>
                                                                        {
                                                                            cItem.imgList.map((imgItem, imgIndex) => {
                                                                                return <img alt="" src={imgItem} key={imgIndex} className={imgIndex === 0 ? "homepage_3_img_border_left" : imgIndex === 3? 'homepage_3_img_border_right' : ''} />
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        :   <></>
                                    }
                                    {
                                        item.type === '5'
                                        ?   <>
                                                <div className="homepage_c_item_type2_item1 homepage_type3">
                                                    {
                                                        item.list.map((cItem, cIndex) => {
                                                            return (
                                                                <div className="homepage_c_item_type2_item1_item" key={cIndex}>
                                                                    <div className="homepage_c_item_type2_item1_item_title">
                                                                        {cItem.title}
                                                                    </div>
                                                                    <div className="homepage_c_item_type2_item1_item_content">
                                                                        {cItem.content}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className="homepage_type3_box">
                                                    {
                                                        item.boxList.map((cItem, cIndex) => {
                                                            if (cItem.type === "1" || cItem.type === "3" )
                                                            {
                                                                return <div className="" key={cIndex}>
                                                                    <div className="homepage_type3_box_title">{cItem.title}</div>
                                                                    {
                                                                        cItem.type === "1"
                                                                        ?   <>
                                                                                {
                                                                                    cItem.content.map((gItem, gIndex) => {
                                                                                        return <div className="homepage_type3_box_1_c" key={gIndex}>{gItem}</div>
                                                                                    })
                                                                                }
                                                                            </>
                                                                        :   <></>
                                                                    }
                                                                    {
                                                                        cItem.type === "3"
                                                                        ?   <div className="homepage_type3_box_1_b">
                                                                            {
                                                                                cItem.list.map((gItem, gIndex) => {
                                                                                    return <div className="homepage_type3_box_1_b_i" key={gIndex}>
                                                                                        <div className="homepage_type3_box_1_b_i_title">{gItem.title}</div>
                                                                                        <img src={gItem.img} alt="" />
                                                                                    </div>
                                                                                })
                                                                            }
                                                                        </div>
                                                                        :   <></>
                                                                    }
                                                                </div>
                                                            } else {
                                                                return <div className="homepage_type3_box_3" key={cIndex}>
                                                                    <div className="homepage_type3_box_3_left">
                                                                        <div className="homepage_type3_box_3_left_title">{cItem.title}</div>
                                                                        <div className="dashed_line mb-10"></div>
                                                                        {
                                                                            cItem.list.map((gItem, gIndex) => {
                                                                                return <div className="homepage_type3_box_3_label" key={gIndex} style={{ marginBottom: gIndex === cItem.list.length - 1? "15px" : 0 }}>
                                                                                    <div className="homepage_type3_box_3_label_t">{gItem.label}</div>
                                                                                    <div className="homepage_type3_box_3_label_c">{gItem.content}</div>
                                                                                </div>
                                                                            })
                                                                        }
                                                                        <div className="dashed_line mb-15"></div>
                                                                        <div className="homepage_type3_box_3_tooltip">{cItem.content}</div>
                                                                    </div>
                                                                    <div className="homepage_type3_box_3_right">
                                                                        {
                                                                            cItem.imgList.map((imgItem, imgIndex) => {
                                                                                return <img src={imgItem} key={imgIndex} alt="" />
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </>
                                        :   <></>
                                    }
                                    {
                                        index < (list.length - 1)
                                        ?   <div className="homepage_c_item_line"></div>
                                        :   <></>
                                    }
                                </div>
                            )
                        }
                    })
                }
            </div>
        </div>
    </>
}
export default HomePage;