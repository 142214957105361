import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

const CheckNow = (props) => {
    const { t } = useTranslation();
    const [isShow, setIsShow] = useState(true);
    let lastHeight = 0;

    const onScrollHandle = async () =>
    {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop; // 获取当前滚动位置
        // 判断滚动方向
        if (scrollTop > lastHeight) {
            // 向下滚动
            setIsShow(false)
        } else if (scrollTop < lastHeight) {
            // 向上滚动
            setIsShow(true)
        }
        // 更新上一次滚动位置
        lastHeight = scrollTop;
    }

    const checkNowHandle = () =>
    {
        props.openDialog()
    }

    useEffect(() => {
        window.addEventListener('scroll', onScrollHandle)
    }, [])

    return (
        <div className="homepage_c_bottom" onClick={() => checkNowHandle()} style={{ bottom: isShow ? '90px' : '-50px'  }}>
            <div className="homepage_c_bottom_text_bg">{t('head.rightMenu.title1')}</div>
        </div>
    )
}
export default CheckNow;