import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Documentation } from '../../img/document/Documentation.svg';
import { ReactComponent as DocumentationCn } from '../../img/document/documentation_cn.svg';
import { ReactComponent as Link } from '../../img/document/link.svg';
import debounce from 'lodash/debounce';


import './index.scss'

const Document = () =>
{
    const { t } = useTranslation();
    const [active, setActive] = useState("overview");
    const [scrollTop, setScrollTop] = useState(0);

    const list = [
        {
            title: t("document.title1"),
            content: t("document.content1"),
            menuTitle: t("document.title1"),
            documentList: [
                { title: t('document.Overall.title1'), name: "Florida DBPR Real Estate Corporation License", type: 'img', link: '/Overall/Florida DBPR Real Estate Corporation License.jpg' },
                { title: t('document.Overall.title2'), name: "Florida DBPR Real Estate Broker or Sales License",type: 'img', link: '/Overall/Florida DBPR Real Estate Broker or Sales License.jpg' },
                { title: t('document.Overall.title3'), name: "Exclusive Marketing Agreement",type: 'img', link: '/Overall/Exclusive Marketing Agreement.jpg' },
                { title: t('document.Overall.title4'), name: "Real Estate Brokers Agreement",type: 'img', link: '/Overall/Real Estate Brokers Agreement.jpg' },
                { title: t('document.Overall.title5'), name: "Real Estate Marketing Agreement (Resort Community - Sample)",type: 'img', link: '/Overall/Real Estate Marketing Agreement (Resort Community - Sample).jpg' },
                { title: t('document.Overall.title6'), name: "Real Estate Brokers Agreement (Resort Community - Sample)",type: 'img', link: '/Overall/Real Estate Brokers Agreement (Resort Community - Sample).jpg' },
                { title: t('document.Overall.title7'), name: "Exclusive Market Rights Referral Agreement (Sample)",type: 'img', link: '/Overall/Exclusive Market Rights Referral Agreement (Sample).jpg' },
            ],
            id: "overview",
            href: "#overview",
            key: "overview"
        },
        {
            title: t("document.title2"),
            content: t("document.content2"),
            menuTitle: t("document.menuTitle2"),
            documentList: [
                { title: t('document.Rentals.title1'), name: "Vacation Home Rental Monthly Statement (Sample)", type: "img", link: '/Rental/Vacation Home Rental Monthly Statement (Sample).jpg' },
                { title: t('document.Rentals.title2'), name: "Short Term Rental Property Income and Expense Log (Sample)", type: "img", link: '/Rental/Short Term Rental Property Income and Expense Log (Sample).jpg' },
                { title: t('document.Rentals.title3'), name: "Short Term Rental Performance (Superhost status - Sample)", type: "img", link: '/Rental/Short Term Rental Performance (Superhost status - Sample).jpg' },
                { title: t('document.Rentals.title4'), name: "Short Term Rental Performance (Occupancy rate - Sample)", type: "img", link: '/Rental/Short Term Rental Performance (Occupancy rate - Sample).jpg' },
                { title: t('document.Rentals.title5'), name: "Short Term Rental Performance (Nightly rate - Sample)", type: "img", link: '/Rental/Short Term Rental Performance (Nightly rate - Sample).jpg' },
                { title: t('document.Rentals.title6'), name: "Short Term Rental Performance (Page views - Sample)", type: "img", link: '/Rental/Short Term Rental Performance (Page views - Sample).jpg' },
                { title: t('document.Rentals.title7'), name: "Utilities Bill (Water and/or Sewer Service - Sample)", type: "img", link: '/Rental/Utilities Bill (Water and or Sewer Service - Sample).jpg' },
                { title: t('document.Rentals.title8'), name: "Utilities Bill (Electric Service - Sample)", type: "img", link: '/Rental/Utilities Bill (Electric Service - Sample).jpg' },
                { title: t('document.Rentals.title9'), name: "Internet And/or Cable TV Service Bill (Sample)", type: "img", link: '/Rental/Internet And or Cable TV Service Bill (Sample).jpg' },
                { title: t('document.Rentals.title10'), name: "Private Pool Maintenance Invoice (Sample)", type: "img", link: '/Rental/Private Pool Maintenance Invoice (Sample).jpg' },
                { title: t('document.Rentals.title11'), name: "Air Conditioning Repair Invoice (Sample)", type: "img", link: '/Rental/Air Conditioning Repair Invoice (Sample).jpg' },
                { title: t('document.Rentals.title12'), name: "House Cleaning Invoice (Sample)", type: "img", link: '/Rental/House Cleaning Invoice (Sample).jpg' },
                { title: t('document.Rentals.title13'), name: "Energy-saving Control (Air Conditioning - Sample)", type: "img", link: '/Rental/Energy-saving Control (Air Conditioning - Sample).jpg' },
                { title: t('document.Rentals.title14'), name: "Property Management Agreement (Sample)", type: "pdf", link: '/Rental/Property Management Agreement (Sample).pdf' },
                { title: t('document.Rentals.title15'), name: "Residential Lease for Single Family Home or Duplex (Sample)", type: "pdf", link: '/Rental/Residential Lease for Single Family Home or Duplex (Sample).pdf' },
            ],
            id: "rentals",
            href: "#rentals",
            key: "rentals"
        },
        {
            title: t("document.title3"),
            content: t("document.content3"),
            menuTitle: t("document.menuTitle3"),
            documentList: [
                { title: t('document.BuyAndSell.title1'), name: "Customer Information Sheet (Sample)", type: "img", link: '/Buy&Sell/Customer Information Sheet (Sample).jpg' },
                { title: t('document.BuyAndSell.title2'), name: "Contract Reservation Form (Sample)", type: "img", link: '/Buy&Sell/Contract Reservation Form (Sample).jpg' },
                { title: t('document.BuyAndSell.title3'), name: "Agreement For Sale And Purchase (Sample)", type: "img", link: '/Buy&Sell/Agreement For Sale And Purchase (Sample).jpg' },
                { title: t('document.BuyAndSell.title4'), name: "Escrow Transmittal for Credit Card (Sample)", type: "img", link: '/Buy&Sell/Escrow Transmittal for Credit Card (Sample).jpg' },
                { title: t('document.BuyAndSell.title5'), name: "Property And/Or Land Survey (Sample)", type: "img", link: '/Buy&Sell/Property And Or Land Survey (Sample).jpg' },
                { title: t('document.BuyAndSell.title6'), name: "Special Warranty Deed (Sample)", type: "img", link: '/Buy&Sell/Special Warranty Deed (Sample).jpg' },
                { title: t('document.BuyAndSell.title7'), name: "Settlement Statement (HUD-1 Sample)", type: "img", link: '/Buy&Sell/Settlement Statement (HUD-1 Sample).jpg' },
                { title: t('document.BuyAndSell.title8'), name: "Community Site Plan (Sample)", type: "img", link: '/Buy&Sell/Community Site Plan (Sample).jpg' },
                { title: t('document.BuyAndSell.title9'), name: "Interactive Site Plan (Sample)", type: "img", link: '/Buy&Sell/Interactive Site Plan (Sample).jpg' },
                { title: t('document.BuyAndSell.title10'), name: "Digital Brochures (Sample)", type: "pdf", link: '/Buy&Sell/Digital Brochures (Sample).pdf' },
                { title: t('document.BuyAndSell.title11'), name: "Floor Plans (Sample)", type: "img", link: '/Buy&Sell/Floor Plans (Sample).jpg' },
                { title: t('document.BuyAndSell.title12'), name: "Virtual Tours (Sample)", type: "link", link: '/Buy&Sell/Virtual Tours (Sample) 复制文本中的链接.txt', href: "https://my.matterportvr.cn/show/?m=UUv35TPStoF" },
            ],
            id: "buyAndSell",
            href: "#buyAndSell",
            key: "buyAndSell"
        },
        {
            title: t("document.title4"),
            content: t("document.content4"),
            menuTitle: t("document.menuTitle4"),
            documentList: [
                { title: t('document.Flipping.title1'), name: "Corporate Resolution to Purchase Real Estate (Sample)", type: "img", link: '/Flipping/Corporate Resolution to Purchase Real Estate (Sample).jpg' },
                { title: t('document.Flipping.title2'), name: "Warranty Deed (Sample)", type: "img", link: '/Flipping/Warranty Deed (Sample).jpg' },
                { title: t('document.Flipping.title3'), name: "Seller's Affidavit (Sample)", type: "img", link: `/Flipping/Seller's Affidavit (Sample).jpg` },
                { title: t('document.Flipping.title4'), name: "Settlement Statement (Sample)", type: "img", link: '/Flipping/Settlement Statement (Sample).jpg' },
                { title: t('document.Flipping.title5'), name: "Deed Vesting Verification (Sample)", type: "img", link: '/Flipping/Deed Vesting Verification (Sample).jpg' },
                { title: t('document.Flipping.title6'), name: "Compliance Agreement, Non-Coercion Statement and Closing Agreement (Sample)", type: "img", link: '/Flipping/Compliance Agreement, Non-Coercion Statement and Closing Agreement (Sample).jpg' },
            ],
            id: "flipping",
            href: "#flipping",
            key: "flipping"
        },
        {
            title: t("document.title5"),
            content: t("document.content5"),
            menuTitle: t("document.menuTitle5"),
            documentList: [
                { title: t('document.Development.title1'), name: "Business License And/Or Local Business Tax Receipt", type: "img", link: '/Development/Business License And Or Local Business Tax Receipt.jpg' },
                { title: t('document.Development.title2'), name: "Certified General Contractor License", type: "img", link: '/Development/Certified General Contractor License.jpg' },
                { title: t('document.Development.title3'), name: "Title Insurance Agency License", type: "img", link: '/Development/Title Insurance Agency License.jpg' },
                { title: t('document.Development.title4'), name: "Site Development Plan Approval (project - Sample)", type: "img", link: '/Development/Site Development Plan Approval (project - Sample).jpg' },
                { title: t('document.Development.title5'), name: "Site Construction Permit (project - Sample)", type: "img", link: '/Development/Site Construction Permit (project - Sample).jpg' },
                { title: t('document.Development.title6'), name: "Application for Residential Building Permit (Sample)", type: "img", link: '/Development/Application for Residential Building Permit (Sample).jpg' },
                { title: t('document.Development.title7'), name: "Acquisition, Development and Construction Contract (Sample)", type: "img", link: '/Development/Acquisition, Development and Construction Contract (Sample).jpg' },
                { title: t('document.Development.title8'), name: "Conceptual Master Plan (Community - Sample)", type: "img", link: '/Development/Conceptual Master Plan (Community - Sample).jpg' },
                { title: t('document.Development.title9'), name: "Updated Lot Map (Community - Sample)", type: "img", link: '/Development/Updated Lot Map (Community - Sample).jpg' },
            ],
            id: "development",
            href: "#development",
            key: "development"
        },
    ];

    
    const changeMenu = (item) =>
    {
        setActive(item.id);
        let e = document.getElementById(item.id);
        if (e) {
            window.scrollTo({
                behavior: 'smooth',
                top: e.offsetTop + 550
            })
        }
    }
    const fnChangeActice = debounce((value) => {
        if (t('lang') == 'CN') {

            if (value > 1100 && value <= 2085) {
                setActive('rentals')
            } else if (value > 2085 && value <= 2900 ) {
                setActive('buyAndSell')
            } else if (value > 2900 && value <= 3440) {
                setActive('flipping')
            } else if (value > 3440) {
                setActive('development')
            } else {
                setActive('overview')
            }
        } else {

            if (value > 1174 && value <= 2100) {
                setActive('rentals')
            } else if (value > 2100 && value <= 3050 ) {
                setActive('buyAndSell')
            } else if (value > 3050 && value <= 3630) {
                setActive('flipping')
            } else if (value > 3630) {
                setActive('development')
            } else {
                setActive('overview')
            }
        }
    }, 100);
    const onScrollHandle = () => {
        setScrollTop(window.scrollY);
        fnChangeActice(window.scrollY);
    }
    const openImageInNewWindow = (data) => {
        if (data.type == 'link')
        {
            window.open(`${data.href}`, '_blank')
        } else {
            window.open(`${window.location.origin + '/homescheckin/static' + data.link}`, '_blank')
        }
    };
    
    useEffect(() => {
        window.addEventListener('scroll', onScrollHandle)
        document.title = t('tabTitle.title3')
    }, []);

    return (
        <div className="container_box pt-100">
            <div className="document_logo">
                {
                    t("lang") === 'EN'
                    ?   <Documentation />
                    :   <DocumentationCn />
                }
            </div>
            <div className="docment_content">
                {
                    list.map((item, index) => {
                        return  <>
                            <div className="docment_item mb-40" key={index} id={item.id}>
                                <div className="docment_item_title mb-20">{item.title}</div>
                                <div className="docment_item_content mb-20">{item.content}</div>
                                {
                                    item.documentList.map((item, index) => {
                                        return  <div className="docment_item_list mb-10" key={index} onClick={() => openImageInNewWindow(item)}>
                                            <span className="docment_item_list_text">{item.title}</span>
                                            <Link className="docment_item_list_logo" />
                                        </div>
                                    })
                                }
                            </div>
                            {
                                index !== list.length - 1 &&
                                <div className="dashed_line_333 mb-40"></div>
                            }
                        </>
                    })
                }
                
                <div className={`document_left_menu ${scrollTop > 520 ? "document_left_menu_fixed" : ""}`}>
                    <div className="document_left_menu_box">
                        {
                            list.map((item, index) => {
                                return  <div className={`document_left_menu_item`} key={index} onClick={() => changeMenu(item)}>
                                    {item.menuTitle}
                                </div>
                            })
                        }
                    </div>
                    <div className="document_left_menu_line_wrap">
                        <div className="document_left_menu_line"></div>
                        <div className="document_left_menu_btn">
                            {
                                list.map((item, index) => {
                                    return  <div className="document_left_menu_btn_item_box" onClick={() => changeMenu(item)}>
                                        <div className={`document_left_menu_btn_item ${active === item.id ? "document_left_menu_btn_item_active" : ""}`} key={index}></div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Document;