import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
 
const ScrollToTop = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  
  useEffect(() => {
    // 每次路由变化时，若页面滚动位置不是在顶部，则滚动到顶部
    const scrollToTop = () => {
      const currentPosition = window.pageYOffset;
      if (currentPosition > 0) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    };
 
    // 防止在初始渲染时执行滚动
    // if (pathname !== window.location.pathname) {
      scrollToTop();
    // }
 
    // 监听路由变化事件
    window.addEventListener('popstate', scrollToTop);
 
    // 组件卸载前移除监听器
    return () => window.removeEventListener('popstate', scrollToTop);
  }, [pathname]);
 
  return <>
      <Helmet>
        <meta name="description" content={t('description')} />
      </Helmet>
  </>;
};
 
export default ScrollToTop;