import React from "react";
import { ReactComponent as Logo1 } from '../../img/footer/logo_footer.svg';
import { ReactComponent as Logo2 } from '../../img/footer/logo_footer_2.svg';
import './index.scss'

const FooterPhone = () =>
{
    return <div className="sf_footer_wrap">
        <div className="sf_footer_c_box">
            <div className="sf_footer_c_logo1"><Logo1 style={{ width: "50px", height: "50px" }} /></div>
            <div className="sf_footer_c_logo2"><Logo2 /></div>
            <div className="sf_footer_c_text">©HomesCheckin</div>
            <div className="sf_footer_c_box_line"></div>
        </div>
    </div>
}

export default FooterPhone;