import React, { useEffect, useState, useRef } from "react";
import { ReactComponent as Logo1 } from '../../img/head/logo1.svg';
import { ReactComponent as Logo2 } from '../../img/head/logo2.svg';
// import { ReactComponent as DoubleArrow } from '../../img/head/double_arrow.svg';
// import { ReactComponent as Menu } from '../../img/head/menu.svg';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { ReactComponent as MenuArrow } from '../../img/head/MenuArrow.svg';

import './index.scss';


const HeaderPhone = (props) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();

    const [isLng, setIsLng] = useState(t('lang') === 'EN');
    const [isShow, setIsShow] = useState(false);

    const touchContainerRef = useRef(null);

    let windowHeight = window.innerHeight;

    const leftMenu = [
        // { logo: <Logo1 className="sf_header_left_item" onClick={() => turnToPath({ type: "logo" })} />, type: "logo" },
        { name: t('head.leftMenu.title1'), type: "scroll", id: "rentals", small_title: t('document.title2_1') },
        { name: t('head.leftMenu.title2'), type: "scroll", id: "buyAndSell", small_title: t('document.title3_1') },
        { name: t('head.leftMenu.title3'), type: "scroll", id: "flipping", small_title: t('document.title4_1') },
        // { name: t('head.leftMenu.title4'), type: "scroll", id: "taxes", small_title: t('document.title5_1') },
        { name: t('head.leftMenu.title5'), type: "scroll", id: "development", small_title: t('document.title5_1') },
    ];
    const rightMenu = [
        { name: t('head.rightMenu.title2'), path: "/pricing", type: "path" },
        { name: t('head.rightMenu.title3'), path: "/documentation", type: "path" },
        { name: t('head.rightMenu.title4'), path: "/contactUs", type: "path" },
    ]
    const changeLng = () =>
    {
        let arr = window.location.pathname.split('/');
        setIsLng(!isLng);
        isLng ? i18n.changeLanguage("cn") : i18n.changeLanguage("en");
        // history.push(`/${isLng ? "cn" : "en"}${arr.length > 2 ? ('/' + arr.splice(2).join('/')) : ''}`)
    }
    const turnToPath = (data) => {
        switch (data.type)
        {
            case "path" :
                history.push({ pathname:`/${t('langPath')}${data.path}`})
                break;
            case "scroll":
                let arr = window.location.pathname.split('/');
                if (arr.length > 2) {
                    history.push({ pathname:`/${t('langPath')}`})
                }
                setTimeout(() => {
                    let e = document.getElementById(data.id);
                    console.log(props.s);
                    
                    if (e) {
                        window.scrollTo({
                            behavior: 'smooth',
                            top: e.offsetTop + (props.state.isShowAdvert ? -140 : -60)
                        })
                    }
                })
                break
            case "logo":
                let arrNew = window.location.pathname.split('/');
                if (arrNew.length > 2) {
                    history.push({ pathname:`/${t('langPath')}`})
                }
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                break;
            default:
                break;
        }
    }

    const onResizeHandle = () =>
    {
        const { innerHeight: height } = window;
        windowHeight = height;
        console.log(windowHeight, '=======windowHeight');
        
    }

    useEffect(() => {
        window.addEventListener('resize', onResizeHandle)
    }, [])

 
  useEffect(() => {
    const container = touchContainerRef.current;
 
    // const handleTouchStart = (event) => {
    //   // 触摸开始时的处理逻辑
    //   console.log('Touch start', event.touches);
    //   setIsShow(false)
    // };
 
    // const handleTouchMove = (event) => {
    //   // 触摸移动时的处理逻辑
    //   console.log('Touch move', event.touches);
    //   setIsShow(false)
    // };
 
    const handleTouchEnd = (event) => {
      // 触摸结束时的处理逻辑
      console.log('Touch end', event.touches);
      setIsShow(false)
    };
    
    // const handlescroll = (event) => {
    //     event.stopPropagation();
    //     return false
    // };
 
    // container.addEventListener('touchstart', handleTouchStart);
    // container.addEventListener('touchmove', handleTouchMove);
    container.addEventListener('touchend', handleTouchEnd);
    // container.addEventListener('scroll', handlescroll, true);
 
    // 清理函数，移除事件监听器
    return () => {
    //   container.removeEventListener('touchstart', handleTouchStart);
    //   container.removeEventListener('touchmove', handleTouchMove);
      container.removeEventListener('touchend', handleTouchEnd);
    //   container.removeEventListener('scroll', handlescroll);
    };
  }, []);

    return (
        <>
            
            <div className="sf_header_container" style={{ top: props.state.isShowAdvert ? "80px": 0 }}>
                <div className="sf_header_container_outBox">
                    
                    <div style={{ display: "flex", alignItems: "center"}} onClick={() => setIsShow(!isShow)} >
                        <Logo1 style={{ width: "25px", height: "23px", marginRight: "7px" }} />
                        {/* <Menu /> */}
                        <div className="sf-header-menu-icon">
                            <div className={isShow ? 'sf-header-menu-icon-1-open sf-header-menu-icon-1' :'sf-header-menu-icon-1'}></div>
                            <div className={isShow ? 'sf-header-menu-icon-2-open sf-header-menu-icon-2' :'sf-header-menu-icon-2'}></div>
                            <div className={isShow ? 'sf-header-menu-icon-3-open sf-header-menu-icon-3' :'sf-header-menu-icon-3'}></div>
                        </div>
                    </div>
                    <div className={"sf_header_lang_btn"} onClick={() => changeLng()}>
                        <div className={`${isLng ? 'sf_header_lang_btn_active' : ''}`}>EN</div>
                        <div className={`${!isLng ? 'sf_header_lang_btn_active' : ''}`}>中文</div>
                    </div>
                </div>
                <Logo2 className="sf_header_center_logo" onClick={() => {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                      });
                }} />
            </div>
            <div 
                className="sf_header_container_box" 
                style={{ top: !isShow ? (windowHeight > 700 ? "-100vh" : ('-700px')) : "0",}} 
                onClick={() => setIsShow(false)}
                ref={touchContainerRef}
            >
                <div className="sf_header_container_box_inside">
                <Logo1 className="sf_header_left_item" style={{ width: "90px", height: "83px", marginBottom: "5px" }} onClick={() => turnToPath({ type: "logo" })} />
                <div className="sf_header_left">
                    <div className="sf_header_menu_item">
                        <div onClick={() => turnToPath({ type: "logo" })}>
                            <MenuArrow className="sf_header_menu_item_svg" /><span>HomesCheckin</span>
                        </div>
                        <div className="sf_header_menu_item_first">
                            {
                                leftMenu.map((item, index) => {
                                    return <div key={index} className="sf_header_left_item sf_header_left_item_menu" onClick={() => turnToPath(item)}>
                                        { item.name + ' - ' }<span>{item.small_title}</span>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="sf_header_right">
                    {
                        rightMenu.map((item, index) => {
                            return <div key={index} className="sf_header_menu_item" onClick={() => turnToPath(item)}>
                                <MenuArrow className="sf_header_menu_item_svg" />
                                { item.name }
                            </div>
                        })
                    }
                </div>
                <div className="head_menu_footer">
                    <div className="sf_footer_c_logo2"><Logo2 style={{ width: "20px", height: "12px" }} /></div>
                    <div className="sf_footer_c_text">©HomesCheckin</div>
                    <div className="sf_footer_c_box_line"></div>
                </div>
                </div>
            </div>
        </>
    )
};
export default HeaderPhone;