import React, {useEffect} from "react";
import { ReactComponent as Arrow } from '../../img/pricing/arrow.svg';
import { ReactComponent as PricingLogo } from '../../img/pricing/PRICING.svg';
import { ReactComponent as RricingCn } from '../../img/pricing/pricing_cn.svg';
import { useTranslation } from "react-i18next";

import './index.scss'

const Pricing = (props) => {
    const { t } = useTranslation();

    const arr = [
        { 
            title: t('pricing.title1'),
            title_small: t('pricing.title1_small'),
            num: 15,
            btnName: t('pricing.btnName'),
            introduce: t('pricing.introduce'),
            articleList: [
                { content: t('pricing.content1.text1'), className: "pricing_article_1" },
                { content: t('pricing.content1.text2'), className: "pricing_article_2" },
                { content: t('pricing.content1.text3'), className: "pricing_article_3" },
                { content: t('pricing.content1.text4'), className: "pricing_article_3" },
            ],
            contentTitle: t('pricing.content1_list.content'),
            contentList: [
                { title: t('pricing.content1_list.title1'), content: t('pricing.content1_list.content1') },
                { title: t('pricing.content1_list.title2'), content: t('pricing.content1_list.content2') },
                { title: t('pricing.content1_list.title3'), content: t('pricing.content1_list.content3') },
                { title: t('pricing.content1_list.title4'), content: t('pricing.content1_list.content4') },
                { title: t('pricing.content1_list.title5'), content: t('pricing.content1_list.content5') },
            ]
        },
        { 
            title: t('pricing.title2'),
            title_small: t('pricing.title2_small'),
            num: 3,
            btnName: t('pricing.btnName'),
            introduce: t('pricing.introduce'),
            articleList: [
                { content: t('pricing.content2.text1'), className: "pricing_article_1" },
                { content: t('pricing.content2.text2'), className: "pricing_article_2" },
                { content: t('pricing.content2.text3'), className: "pricing_article_3" },
            ],
            contentTitle: t('pricing.content2_list.content'),
            contentList: [
                { title: t('pricing.content2_list.title1'), content: t('pricing.content2_list.content1') },
                { title: t('pricing.content2_list.title2'), content: t('pricing.content2_list.content2') },
                { title: t('pricing.content2_list.title3'), content: t('pricing.content2_list.content3') },
                { title: t('pricing.content2_list.title4'), content: t('pricing.content2_list.content4') },
                { title: t('pricing.content2_list.title5'), content: t('pricing.content2_list.content5') },
            ]
        },
    ];
    const openDialog = () =>
    {
        props.openDialog();
    }
    
    useEffect(() => {
        document.title = t('tabTitle.title2')
    }, [])

    return (
        <div className="sf_pricing_wrap">
            <div className="sf_pricing_container pt-100">
                <div className="sf_pricing_logo">
                    {
                        t("lang") === 'EN'
                        ?   <PricingLogo className="sf_pricing_logo_svg" />
                        :   <RricingCn className="sf_pricing_logo_svg" />
                    }
                </div>
                <div className="sf_pricing_container_card">
                    {
                        arr.map((item, index) => {
                            return <div className="sf_pricing_card" key={index}>
                                <div className="sf_pricing_card_title">{item.title}</div>
                                <div className="sf_pricing_card_article_text">{item.title_small}</div>
                                <div className="sf_pricing_card_article">
                                    {
                                        item.articleList.map((aItem, aIndex) => {
                                            return <div className={`sf_pricing_card_article_text ${aItem.className} ${aIndex === item.articleList.length - 1 ? "mb-30" : ""}`} key={aIndex}>
                                                {
                                                    aIndex === 0
                                                    ?   <>
                                                            <div>
                                                                <span>{item.num}</span>
                                                                {aItem.content}
                                                            </div>
                                                            <div className="sf_pricing_card_btn" onClick={() => openDialog()}>
                                                                {item.btnName}
                                                            </div>
                                                        </>
                                                    :   aItem.content
                                                }
                                            </div>
                                        })
                                    }
                                </div>
                                <div className="dashed_line_333 mb-30"></div>
                                <div className="sf_pricing_card_content">
                                    <div className="pricing_card_content_title sf_pricing_card_article_text mb-30">{item.contentTitle}</div>
                                    <div className="pricing_card_content_title_list">
                                        {
                                            item.contentList.map((cItem, cIndex) => {
                                                return <div className="pricing_card_content_title_list_item mb-20" key={cIndex}>
                                                    <div className="pricing_card_content_left">
                                                        <Arrow />
                                                    </div>
                                                    <div className="pricing_card_content_right">
                                                        <div className="pricing_card_content_title_list_item_title">{cItem.title}</div>
                                                        <div className="pricing_card_content_title_list_item_content">{cItem.content}</div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="dashed_line_333 mb-30"></div>
                                <div className="pricing_card_introduce">{item.introduce}</div>
                            </div>
                        })  
                    }
                </div>
            </div>
        </div>
    )
}

export default Pricing;