import React from 'react'
import FrameRouter from './router/frameRoute'

import './App.css';
import './css/index.scss'
import './font/iconfont.css'

import { initReactI18next } from 'react-i18next'
import i18n from 'i18next';

import enJson from './i18n/en.json'
import zhJson from './i18n/zh.json'

var language = navigator.language || navigator.userLanguage; 
console.log(language);

let lang = window.location.pathname.split('/')[1];
console.log(lang, '11111111');

i18n.use(initReactI18next).init({
	resources: {
		en: {
      translation: {
        ...enJson
      }
    },
		cn: {
      translation: {
        ...zhJson
      }
    },
	},
	lng: lang ? lang : (language == 'zh-CN' || language == 'zh-TW' ? 'cn' : 'en'),
	fallbackLng: 'en',
	interpolation: {
		escapeValue: false, // 不要对翻译的文本进行转义，以支持 HTML 标签
	},
})

class App extends React.Component {
  render ()
  {
    return (
      <FrameRouter />
    );
  }
}

export default App;
