import React, { useState } from "react";
import { ReactComponent as Logo1 } from '../../img/head/logo1.svg';
import { ReactComponent as Logo2 } from '../../img/head/logo2.svg';
import { ReactComponent as DoubleArrow } from '../../img/head/double_arrow.svg';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import './index.scss';


const Header = (props) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();

    const [isLng, setIsLng] = useState(t('lang') === 'EN');
    const leftMenu = [
        { logo: <Logo1 className="sf_header_left_item" onClick={() => turnToPath({ type: "logo" })} />, type: "logo" },
        { name: t('head.leftMenu.title1'), type: "scroll", id: "rentals" },
        { name: t('head.leftMenu.title2'), type: "scroll", id: "buyAndSell" },
        { name: t('head.leftMenu.title3'), type: "scroll", id: "flipping" },
        { name: t('head.leftMenu.title4'), type: "scroll", id: "taxes" },
        { name: t('head.leftMenu.title5'), type: "scroll", id: "development" },
    ];
    const checkNowHandle = () =>
    {
        props.openDialog()
    }
    const rightMenu = [
        {   type: 'render', name: t('head.rightMenu.title1'), render: ()  => {
            return <div className="sf_header_right_item_color" onClick={(e) => checkNowHandle(e)}>
                        <DoubleArrow />
                        <div className="sf_header_right_item_text">
                            {t('head.rightMenu.title1')}
                            <div className="sf_header_right_item_color_border">
                                <div className="sf_header_right_item_color_border_inside">
                                
                                </div>
                            </div>
                        </div>
                </div>
        } },
        { name: t('head.rightMenu.title2'), path: "/pricing", type: "path" },
        { name: t('head.rightMenu.title3'), path: "/documentation", type: "path" },
        { name: t('head.rightMenu.title4'), path: "/contactUs", type: "path" },
    ]
    const changeLng = () =>
    {
        let arr = window.location.pathname.split('/');
        setIsLng(!isLng);
        isLng ? i18n.changeLanguage("cn") : i18n.changeLanguage("en");
        // history.push(`/${isLng ? "cn" : "en"}${arr.length > 2 ? ('/' + arr.splice(2).join('/')) : ''}`)
    }
    const turnToPath = (data) => {
        switch (data.type)
        {
            case "path" :
                history.push({ pathname:`/${t('langPath')}${data.path}`})
                break;
            case "scroll":
                let arr = window.location.pathname.split('/');
                if (arr.length > 2) {
                    history.push({ pathname:`/${t('langPath')}`})
                }
                setTimeout(() => {
                    let e = document.getElementById(data.id);
                    if (e) {
                        window.scrollTo({
                            behavior: 'smooth',
                            top: e.offsetTop - (props.state.isShowAdvert ? 110 : 80)
                        })
                    }
                })
                break
            case "logo":
                let arrNew = window.location.pathname.split('/');
                if (arrNew.length > 2) {
                    history.push({ pathname:`/${t('langPath')}`})
                }
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                break;
            default:
                break;
        }
    }

    return (
        <div className="sf_header_container" style={{ top: props.state.isShowAdvert ? "36px": 0 }}>
            
            <Logo2 className="sf_header_center_logo" onClick={() => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  });
            }} />
            <div className="sf_header_container_box">
                <div className="sf_header_left">
                    {
                        leftMenu.map((item, index) => {
                            if (item.type === 'logo')
                            {
                                return item.logo
                            } else {

                                return <div key={index} className="sf_header_left_item sf_header_left_item_menu" onClick={() => turnToPath(item)}>
                                    { item.name }
                                </div>
                            }
                        })
                    }
                </div>
                <div className="sf_header_right">
                    {
                        rightMenu.map((item, index) => {
                            if (item.type === 'render')
                            {
                                return item.render()
                            } else {

                                return <div key={index} className="sf_header_right_item" onClick={() => turnToPath(item)}>
                                    { item.name }
                                </div>
                            }
                        })
                    }
                    <div className={"sf_header_lang_btn"} onClick={() => changeLng()}>
                        <div className={`${isLng ? 'sf_header_lang_btn_active' : ''}`}>EN</div>
                        <div className={`${!isLng ? 'sf_header_lang_btn_active' : ''}`}>中文</div>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default Header;