import React from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from '@ant-design/icons';
import "./advert.scss";

const Advert = (props) => {
    const { t } = useTranslation();

    return (
        <div className="advert">
            <p dangerouslySetInnerHTML={{ __html: t("advert") }} onClick={() => props.openAdvertDialog()}></p>
            <div className="advert_close_icon" onClick={() => props.closeAdvert()}>
                <CloseOutlined/>
            </div>
        </div>
    );
};

export default Advert;