import React, { Component } from 'react';
import { debounce } from '../frame/publicMethods'
import ScrollToTop from './scrollToTop';
import Header from '../components/header';
import Footer from '../components/footer';
import ComHead from "../components/comHead";
import UseDialogCom from "../hooks/useDialogCom";
import SuccessMessage from '../hooks/successMessage';
import HeaderPhone from '../components/header/header_phone';
import FooterPhone from '../components/footer/footer_phone';
import ComHeadPhone from '../components/comHead/comHead_phone';
import SuccessMessagePhone from '../hooks/successMessagePhone';
import UseDialogComPhone from '../hooks/useDialogComPhone';
import CheckNow from '../hooks/checkNow';
import Advert from '../hooks/advert';
import AdvertPhone from '../hooks/advertPhone';
import AdvertDialog from '../hooks/advertDialog';
import AdvertDialogPhone from '../hooks/advertDialogPhone';

class Frame extends Component {
    constructor(props) {
        super(props);

        this.state = {
            windowWidth: window.innerWidth,
            isShowAdvert: true,
            isShowAdvertDialog: false,
            isShowScroll: true,
        };
        this.onhandleResize = debounce(this.handleResize, 200)
    }

    reload = () => {}

    handleResize = () =>
    {
        let width = window.innerWidth;
        this.setState({
            windowWidth: width,
        })
    }

    componentDidMount () 
    {
        window.addEventListener('resize', this.onhandleResize)
    }

    componentWillUnmount ()
    {
        window.removeEventListener('resize', this.onhandleResize)
    }

    openDialog = () =>
    {
        this.dialogRef.openDialog();
    }
    
    openSuccess =  () =>
    {
        this.successMessageRef.openSuccess();
    }
    closeAdvert = () =>
    {
        this.setState({
            isShowAdvert: false,
        })
    }
    openAdvertDialog = () =>
    {
        this.setState({
            isShowAdvertDialog: true,
        })
    }
    closeAdvertDialog = () =>
    {
        this.setState({
            isShowAdvertDialog: false,
        })
    }

    removeScroll = () =>
    {
        this.setState({
            isShowScroll: false,
        })

        setTimeout(() => {
            this.setState({
                isShowScroll: true,
            })
        }, 1000)
    }

    render() {

        const { model } = this.props;

        const { windowWidth, isShowAdvert, isShowAdvertDialog, isShowScroll } = this.state;

        const childrenBody = React.Children.map(this.props.children, child =>
            React.cloneElement(child, { reload: this.reload, ...this })
        );

        switch (model) {
            case 'comHead':
            {
                return (
                    <div className='sf_wrapper'>
                        {
                            isShowScroll
                            ?   <ScrollToTop />
                            :   <></>
                        }

                        {
                            isShowAdvert
                            ?   <>
                                    {
                                        windowWidth <= 1280
                                        ?   <AdvertPhone {...this} />
                                        :   <Advert {...this} />
                                    }
                                </>
                            :   <></>
                        }
                        
                        {
                            windowWidth <= 1280
                            ?   <HeaderPhone {...this} />
                            :   <Header {...this} />
                        }
                        
                        <div className='sf-content-wrap' style={{ paddingTop: isShowAdvert ? (windowWidth <= 1280 ? "80px" : "36px") : "" }}>
                            {
                                windowWidth <= 1280
                                ?   <ComHeadPhone />
                                :   <ComHead />
                            }
                            {childrenBody}
                            {
                                windowWidth <= 1280
                                ?   <CheckNow {...this} />
                                :   <></>
                            }
                        </div> 
                        {
                            windowWidth <= 1280
                            ?   <>
                                    <FooterPhone />
                                    <UseDialogComPhone {...this} onRef={ref => this.dialogRef = ref} />
                                    <SuccessMessagePhone onRef={ref => this.successMessageRef = ref} />
                                </>
                            :   <>
                                    <Footer />
                                    <UseDialogCom {...this} onRef={ref => this.dialogRef = ref} />
                                    <SuccessMessage onRef={ref => this.successMessageRef = ref} />
                                </>
                        }
                        {
                            isShowAdvertDialog
                            ?   <>
                                    {
                                        windowWidth <= 1280
                                        ?   <AdvertDialogPhone {...this} />
                                        :   <AdvertDialog {...this} />
                                    }
                                </>
                            :   <></>
                        }
                    </div>
                )
            }
            default:
                {
                    return (
                        <div className='sf_wrapper'>
                            <ScrollToTop />
                            {
                                isShowAdvert
                                ?   <>
                                        {
                                            windowWidth <= 1280
                                            ?   <AdvertPhone {...this} />
                                            :   <Advert {...this} />
                                        }
                                    </>
                                :   <></>
                            }

                            {
                                windowWidth <= 1280
                                ?   <HeaderPhone {...this} />
                                :   <Header {...this} />
                            }
                            
                            <div className='sf-content-wrap' style={{ paddingTop: isShowAdvert ?(windowWidth <= 1280 ? "80px" : "36px") : "" }}>
                                {childrenBody}
                                {
                                    windowWidth <= 1280
                                    ?   <CheckNow {...this} />
                                    :   <></>
                                }
                                
                            </div> 
                            {
                                windowWidth <= 1280
                                ?   <>
                                        <FooterPhone />
                                        <UseDialogComPhone {...this} onRef={ref => this.dialogRef = ref} />
                                        <SuccessMessagePhone onRef={ref => this.successMessageRef = ref} />
                                    </>
                                :   <>
                                        <Footer />
                                        <UseDialogCom {...this} onRef={ref => this.dialogRef = ref} />
                                        <SuccessMessage onRef={ref => this.successMessageRef = ref} />
                                    </>
                            }
                            {
                                isShowAdvertDialog
                                ?   <>
                                        {
                                            windowWidth <= 1280
                                            ?   <AdvertDialogPhone {...this} />
                                            :   <AdvertDialog {...this} />
                                        }
                                    </>
                                :   <></>
                            }
                        </div>
                    );
                }
        }
    }
}

export default Frame