import React from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from '@ant-design/icons';
import "./advert.scss";

const AdvertPhone = (props) => {
    const { t } = useTranslation();

    return (
        <div className="advert">
            <p onClick={() => props.openAdvertDialog()}>
                <div>{t('advert1')}</div>
                <div>{t('advert2')}</div>
                <a>{t('head.rightMenu.title1')}</a>
            </p>
            <div className="advert_close_icon" onClick={() => props.closeAdvert()}>
                <CloseOutlined/>
            </div>
        </div>
    );
};

export default AdvertPhone;