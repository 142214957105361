import React from "react";
import { ReactComponent as Logo1 } from '../../img/logo4.svg';
import { ReactComponent as Logo2 } from '../../img/head/logo2.svg';

import "./index.scss";

const ComHeadPhone = () => {
    return  <div className="sf_com_head">
                <div className="sf_com_head_logo">
                    <Logo1 style={{ width: "72px", height: "72px" }} />
                </div>
                <div className="sf_com_head_logo_bottom">
                    <div className="sf_com_head_logo_bottom_logo"><Logo2 style={{ width: "20px", height: "12px" }} /></div>
                    <div className="sf_com_head_logo_bottom_text">©HomesCheckin</div>
                    <div className="sf_com_head_logo_bottom_line"></div>
                </div>
            </div>
};

export default ComHeadPhone;