import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import './successMessage.scss'

const SuccessMessagePhone = (props) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [isShow, setIsShow] = useState(false);

    const openSuccess = () =>
    {
        setIsOpen(true);
        setTimeout(() => {
            setIsShow(true);
        }, 100)
        setTimeout(() => {
            setIsOpen(false);
            setIsShow(false);
        }, 3000)
    }

    if (props.onRef)
    {
        props.onRef({ openSuccess });
    }
    
    return (
        <div className="sf_alert_wrap" style={{ display: isOpen ? "" : "none" }} onClick={() => {
                setIsOpen(false);
                setIsShow(false);
            }}>
            <div className={`sf_alert alert-success`} style={{ top: isShow ? "100px" : "-50px" }}>
                {t("success")}
            </div>
        </div>
    )
}
export default SuccessMessagePhone;