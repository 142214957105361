import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from '@ant-design/icons';
import { ReactComponent as Boost } from '../img/advert/Boost.svg';
import { useHistory } from "react-router";
import './advertDialog.scss'

const AdvertDialog = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const [isShow, setIsShow] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsShow(true);
        }, 100)
    }, [])

    const closeDialog = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsShow(false);
        setTimeout(() => {
            props.closeAdvertDialog()
        }, 1000);
        return false
    }

    const openCheckNow = () => {
        setIsShow(false);
        setTimeout(() => {
            props.closeAdvertDialog();
            props.removeScroll();
            history.push({
                pathname: `/${t('langPath')}/contactUs`,
                state: {
                    formData: {
                        message: t('langPath') == 'en' ? 'I hope to learn more about the <Free Home Theme Upgrade Plan>.' : '我希望进一步了解<度假屋主题免费升级计划>。'
                    }
                }
            });
            let location = props.props.children.props.location;
            if (location.pathname == '/en/contactUs' || location.pathname == '/cn/contactUs')
            {
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }
        }, 1000)
    }

    return (
        <>
            <div className="advert_dialog" onClick={(e) => {
                    closeDialog(e)
            }}>
                <div className="advert_dialog_box" style={{ top: isShow ? '50%' : '-20%' }}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        return false
                    }}
                >
                    <img src={require('../img/advert/Upgrade.jpg')} />
                    <div className="advert_dialog_close" onClick={(e) => {
                        closeDialog(e);
                    }}>
                        <CloseOutlined />
                    </div>
                    <div className="advert_dialog_content">
                        <div className="advert_dialog_article">
                            
                            <div className="advert_dialog_content_top d-f">
                                <p dangerouslySetInnerHTML={{ __html: t('advertDialog.title1') }}></p>
                                <Boost />
                            </div>
                            <div className="advert_dialog_content_bottom">{t('advertDialog.title2')}</div>
                        </div>
                        <div className="advert_dialog_btn" onClick={() => openCheckNow()}>
                            {t('head.rightMenu.title1')}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdvertDialog;