import React from "react";
import { ReactComponent as Logo1 } from '../../img/logo4.svg';
import { ReactComponent as Logo2 } from '../../img/head/logo2.svg';

import "./index.scss";

const ComHead = () => {
    return  <div className="sf_com_head">
                <div className="sf_com_head_logo">
                    <Logo1 />
                </div>
                <div className="sf_com_head_logo_bottom">
                    <div className="sf_com_head_logo_bottom_logo"><Logo2 /></div>
                    <div className="sf_com_head_logo_bottom_text">©HomesCheckin</div>
                    <div className="sf_com_head_logo_bottom_line"></div>
                </div>
            </div>
};

export default ComHead;