import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomePage from '../pages/homePage'

import Frame from '../frame/frame'
import { Redirect } from 'react-router';
// import Template from "../pages/template";
import { debounce } from '../frame/publicMethods'
import Pricing from "../pages/pricing";
import Document from "../pages/document";
import ContactUs from "../pages/contactUs";
import HomePagePhone from "../pages/homePage_phone";
import DocumentPhone from "../pages/document/document_phone";
import ContactUsPhone from "../pages/contactUs/contactUs_phone";
import PricingPhone from "../pages/pricing/pricing_phone";

class FrameRouter extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            windowWidth: window.innerWidth,
        }
        this.onhandleResize = debounce(this.handleResize, 200)
    }

    handleResize = () =>
    {
        let width = window.innerWidth;
        this.setState({
            windowWidth: width,
        })
    }

    componentDidMount () 
    {
        window.addEventListener('resize', this.onhandleResize)
    }

    componentWillUnmount ()
    {
        window.removeEventListener('resize', this.onhandleResize)
    }

    render () {
        const { windowWidth } = this.state;
        return <>
            <Router basename="/">
                <Route path="/" render={({match}) => {
                    console.log(match);
                    
                    if (window.location.pathname === '/') {
                        var language = navigator.language || navigator.userLanguage; 
                        
                        if (language == 'zh-CN' || language == 'zh-TW')
                        {
                            return <Redirect to={{ pathname: '/cn'}} />
                        } else {
                            return <Redirect to={{ pathname: '/en'}} />
                        }
                    }
                }} />
                <Route path="/:lang" render={({ match }) => {
                    let pathBase = match.path;
                    return (
                        <Router basename="/">
                            <Switch >
                                {/* <FrameRoute exact path={pathBase + '/'} component={ windowWidth < 1300 ? QualitySequencePhone : QualitySequence} /> */}
                                <FrameRoute exact path={pathBase + '/'} component={windowWidth <= 1280 ? HomePagePhone : HomePage} />
                                <FrameRoute exact path={pathBase + '/pricing'} component={windowWidth <= 1280 ? PricingPhone : Pricing} model="comHead" />
                                <FrameRoute exact path={pathBase + '/documentation'} component={windowWidth <= 1280 ? DocumentPhone : Document} model="comHead" />
                                <FrameRoute exact path={pathBase + '/contactUs'} component={windowWidth <= 1280 ? ContactUsPhone : ContactUs} model="comHead" />
                                {/*  */}
                            </Switch >
                        </Router>
                    )
                }} />
            </Router>
        </>   
    }
}

const FrameRoute = ({ component: Component, model, ...rest }) => (
    <Route {...rest} render={(props) => (
        
        <Frame model={model} >
            <Component {...props} />
        </Frame>
    )} />
)

export default FrameRouter;