import React, {useEffect, useState} from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { ReactComponent as Logo } from '../img/footer/logo_footer.svg';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input } from 'antd';
import WidgetAjax from '../widget/WidgetAjax.js';

import './useDialogCom.scss';

const UseDialogCom  = (props) =>
{
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [form] = Form.useForm();
    const [place, setPlace] = useState({});

    const openDialog = () =>
    {
        form.resetFields();
        setLoading(false);
        setIsOpen(true);
        setTimeout(() => {
            setIsShow(true);
        }, 100);
    }
    
    if (props.onRef) {
        props.onRef({ openDialog })
    }

    const onFinish = (values) =>
    {
        setLoading(true)
        const onSubmit = (res) => {
            if (res.status === 0)
            {
                form.resetFields();
                setIsOpen(false);
                setLoading(false);
                props.openSuccess();
            }
        }

        WidgetAjax.ajax({
            // url: `http://218.26.73.114:18121/HomescheckinAPI/user_comment/create`,
            url: `https://homescheckin.com/HomescheckinAPI/user_comment/create`,
            params: { ...values, ...place },
            callback: onSubmit
        })
    }
    useEffect(() => {
        WidgetAjax.ajax({
            url: `https://ipapi.co/json/`,
            params: {},
            callback: (res) => {
                // console.log("Country:", res.country_name, "City:", res.city);
                setPlace({
                    country: res.country_name,
                    city: res.city
                })
            }
        })
    }, [])

    return (
        <div className='useDialogCom_wrap' onClick={() => {
            setIsShow(false);
            setTimeout(() => {
                setIsOpen(false);
            }, 1000);
        }} style={{ display: isOpen ? '' : "none" }}>
            <div className='useDialogCom_box' onClick={(e) => {
                e.stopPropagation();
                return false;
            }} style={{ top: isShow ? "50%" : "-50%" }}>
                <div className='useDialogCom_box_inside'>
                    <div className='useDialogCom_box_close' onClick={() => {
                        setIsShow(false);
                        setTimeout(() => {
                            setIsOpen(false);
                        }, 1000);
                    }}><CloseOutlined /></div>
                    <div className='useDialogCom_box_head'>
                        <Logo />
                        <div className='useDialogCom_box_line'></div>
                        <div className=''>
                            <div className='useDialogCom_box_title'>{t('head.rightMenu.title1')}</div>
                            <div className='useDialogCom_box_title_second' style={{ display: "flex" }} dangerouslySetInnerHTML={{ __html: t('homePage.title1') }}></div>
                        </div>
                    </div>
                    <Form
                        form={form}
                        onFinish={onFinish}
                        className='useDialogCom_box_form'
                    >
                        <Form.Item>
                            <div className='useDialogCom_box_form_first'>
                                <Form.Item noStyle name={"email"} rules={[{ required: true, message: t('contactUs.errorTooltip') }]}>
                                    <Input placeholder={t('contactUs.placeholder1') + ' *'} variant="filled" />
                                </Form.Item>
                                <span className='useDialogCom_box_form_split'>--</span>
                                <Form.Item noStyle name={"phone"} rules={[{ required: true, message: t('contactUs.errorTooltip') }]}>
                                    <Input placeholder={t('contactUs.placeholder2') + ' *'} variant="filled" />
                                </Form.Item>
                            </div>
                        </Form.Item>
                        <Form.Item name="message" rules={[{ required: true, message: t('contactUs.errorTooltip') }]} className='useDialogCom_box_form_second'>
                            <Input 
                                placeholder={t('contactUs.placeholder3') + ' *'} 
                                addonAfter={<Button type='primary' htmlType='sumbit' loading={loading}>{t('head.rightMenu.title5')}</Button>} />
                        </Form.Item>
                    </Form>
                    <div className='useDialogCom_box_bottom_text' dangerouslySetInnerHTML={{ __html: t('contactUs.tooltip') }}></div>
                </div>
            </div>
        </div>
    );
};
export default UseDialogCom;