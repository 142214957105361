import { useEffect } from 'react';
 
const PreventH5Flip = () => {
    useEffect(() => {
        const lockOrientation = () => {
          // 获取当前视口宽度和高度
          const { innerWidth: width, innerHeight: height } = window;
          // 设置屏幕方向基于视口尺寸
          if (width > height) {
            document.body.style.orientation = 'landscape';
          } else {
            document.body.style.orientation = 'portrait';
          }
        };
     
        // 初始化方向锁定
        lockOrientation();
     
        // 监听resize事件以更新屏幕方向
        window.addEventListener('resize', lockOrientation);
     
        // 清理函数，组件卸载时移除事件监听
        return () => window.removeEventListener('resize', lockOrientation);
      }, []);
     
      return null;
};
 
export default PreventH5Flip;