import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from '@ant-design/icons';
import { ReactComponent as Boost } from '../img/advert/Boost.svg';
import { useHistory } from "react-router";
import './advertDialog.scss'

const AdvertDialogPhone = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const [isShow, setIsShow] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsShow(true);
        }, 100)
    }, [])

    const closeDialog = () => {
        setIsShow(false);
        setTimeout(() => {
            props.closeAdvertDialog()
        }, 1000)
    }

    const openCheckNow = () => {
        setIsShow(false);
        setTimeout(() => {
            props.closeAdvertDialog();
            props.removeScroll();
            history.push({
                pathname: `/${t('langPath')}/contactUs`,
                state: {
                    formData: {
                        message: t('langPath') == 'en' ? 'I hope to learn more about the <Free Home Theme Upgrade Plan>.' : '我希望进一步了解<度假屋主题免费升级计划>。'
                    }
                }
            });
            let location = props.props.children.props.location;
            
            if (location.pathname == '/en/contactUs' || location.pathname == '/cn/contactUs')
            {
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }
        }, 1000)
    }

    return (
        <>
            <div className="advert_dialog" onClick={() => {
                closeDialog()
                return false;
            }}>
                <div className="advert_dialog_box" style={{ bottom: isShow ? '40px' : '-70%' }}

                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        return false
                    }}
                >
                    <img src={require('../img/advert/Upgrade.jpg')} />
                    <div className="advert_dialog_close" onClick={() => closeDialog()}>
                        <CloseOutlined />
                    </div>
                    <div className="advert_dialog_content">
                        <div className="advert_dialog_article">
                            
                            <div className={`advert_dialog_content_top d-f ${t('langPath') == 'cn' ? 'advert_dialog_content_top_cn' : ''}`}>
                                <p dangerouslySetInnerHTML={{ __html: t('advertDialog.title1') }}></p>
                                <Boost style={{ width: '18px', height: '18px' }} />
                            </div>
                            <div className={`advert_dialog_content_bottom ${t('langPath') == 'cn' ? "advert_dialog_content_bottom_cn" : ""}`}>{t('advertDialog.title2')}</div>
                        </div>
                        <div className="advert_dialog_btn" onClick={() => openCheckNow()}>
                            {t('head.rightMenu.title1')}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdvertDialogPhone;