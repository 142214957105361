import React, { useState, useEffect } from "react";
import { ReactComponent as Avatar } from "../../img/contactUs/avatar.svg"; 
import { ReactComponent as ContactUsTitle } from "../../img/contactUs/ContactUs.svg"; 
import { ReactComponent as ContactusCn } from "../../img/contactUs/contactus_cn.svg"; 
import { ReactComponent as Context } from "../../img/contactUs/context.svg"; 
import { ReactComponent as ContextCn } from "../../img/contactUs/context_cn.svg"; 
import { ReactComponent as Quotes } from "../../img/contactUs/Quotes.svg"; 
import { useTranslation } from "react-i18next";
import { Form, Input, Button } from 'antd';
import { Space, Swiper } from 'antd-mobile';
import WidgetAjax from '../../widget/WidgetAjax.js'

import './index.scss'

const { TextArea } = Input;

const ContactUsPhone = (props) => {

    const state = props.location.state || {};
    let formData = state.formData || {};
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [place, setPlace] =  useState({});

    const commentList = [
        { 
            img: require('../../img/contactUs/avatar1.jpg'),
            num: "★★★★★",
            title: "Hilda Gabriela",
            title_small: "Guatemala City, Guatemala",
            content: "Excelente alojamiento; limpio, cómodo, amplio y muy cercano a los parques!! Cassidy atiende las dudas y requerimientos inmediatamente y el alojamiento es muy familiar.. nos sentimos como en casa!",
        },
        { 
            img: require('../../img/contactUs/avatar2.jpg'),
            num: "★★★★★",
            title: "Arpita",
            title_small: "Santa Barbara, California",
            content: "We had a fantastic stay at beautiful home ! Very clean , spacious and well stocked house . The pool and game room were awesome great plus !! Cassidy was extremely responsive and was an available when we needed some help late in the night . Always willing to accommodate requests. Will definitely come back and also highly recommend this place and the host !!",
        },
        { 
            img: require('../../img/contactUs/avatar3.jpg'),
            num: "★★★★★",
            title: "Sherelle",
            title_small: "Nassau, The Bahamas",
            content: "Cassidy was an awesome host! She was very responsive and extremely helpful with every question I had. My family and I LOVED her place and we can't wait to stay again. The kids enjoyed the private pool as well as the community pool. Shopping was at our fingertips, literally within 5 or 10 minutes drive.",
        },
        { 
            img: require('../../img/contactUs/avatar4.jpg'),
            num: "★★★★★",
            title: "Rachel",
            title_small: "Voorhees Township, New Jersey",
            content: "The house was just as described but even better in person. Cassidy was so quick to respond when I messaged with a question or two during our stay and I will DEFINITELY be going back ! The resort amenities were nice but we honestly loved the private pool and jacuzzi at the house !",
        },
        { 
            img: require('../../img/contactUs/avatar5.jpg'),
            num: "★★★★★",
            title: "Taciane",
            title_small: "Fall River, Massachusetts",
            content: "A casa correspondia com tudo descrito , muito limpa , ambiente tranquilo  e familiar , todos os utensílios de cozinha que ajudou muito nos dias  de descanso em casa ,a comunicação com o anfitrião sempre muito ágil e  rápido para responder e atender nossas necessidades , voltaremos mas  vezes para essa casa com toda certeza 🥰",
        },
    ]

    // const [number, setNumber] = useState(0);
    const formList = [
        { type: 'input', props: 'email', placeholder: t('contactUs.placeholder1'), label: t('contactUs.label1'), rules: [{ required: true, message: t('contactUs.errorTooltip') }] },
        { type: 'input', props: 'phone', placeholder: t('contactUs.placeholder2'), label: t('contactUs.label2'), rules: [{ required: true, message: t('contactUs.errorTooltip') }] },
        { type: 'textarea', props: 'message', placeholder: t('contactUs.placeholder3'), label: t('contactUs.label3'), rules: [{ required: true, message: t('contactUs.errorTooltip') }] },
    ];

    const onFinish = (values) => {
        setLoading(true)
        const onSubmit = (res) => {
            if (res.status === 0)
            {
                form.resetFields();
                props.openSuccess();
            }
            setLoading(false)
        }

        WidgetAjax.ajax({
            // url: "http://218.26.73.114:18121/HomescheckinAPI/user_comment/create",
            url: `https://homescheckin.com/HomescheckinAPI/user_comment/create`,
            params: { ...values, ...place },
            callback: onSubmit
        })
    };

    useEffect(() => {
        if (formData.message) {
            setTimeout(() => {
                let e = document.getElementById("contactUs");
                if (e) {
                    window.scrollTo({
                        behavior: 'smooth',
                        top: e.offsetTop - 150
                    })
                }
            }, 1000)
        }
        document.title = t('tabTitle.title4');
        WidgetAjax.ajax({
            url: `https://ipapi.co/json/`,
            params: {},
            callback: (res) => {
                setPlace({
                    country: res.country_name,
                    city: res.city
                })
            }
        })
    }, []);

    return (
        <div className="contact_us_wrap">
            <div className="contact_us_title" style={{ height: "54px", overflow: "hidden", display: "flex", alignItems: "center", justifyContent: "center" }}>
                {
                    t('lang') === 'EN'
                    ?   <ContactUsTitle className="contact_us_title_svg" style={{ width: "160px", height: "63px", position: "relative", top: "-5px" }} />
                    :   <ContactusCn className="contact_us_title_svg" />
                }
            </div>
            <div className="contact_us_context">
                <div className="contact_us_context_avatar">
                    <Quotes style={{ width: "44px", height: "38px" }} />
                    <div className="contact_us_context_avatar_right">
                        <div className="contact_us_context_avatar_box">
                            <div className="contact_us_context_avatar_title1">Founder</div>
                            <div className="contact_us_context_avatar_title2">HomesCheckin</div>
                        </div>
                        <Avatar className="contact_us_context_avatar_logo" />
                    </div>
                </div>
                <div>
                    
                    {
                        t('lang') === 'EN'
                        ?   <Context className="contact_us_context_text" />
                        :   <ContextCn className="contact_us_context_text" />
                    }
                </div>
            </div>
            <div className="container_box_contactUs">
                <div className="container_comment_box">
                    <Space direction='vertical' block>
                        <Swiper
                            slideSize={90} 
                        >
                            {
                                commentList.map((cItem, cIndex) => {
                                    return <Swiper.Item key={cIndex}>
                                        <div className="container_comment_list_item">
                                            <div className="container_comment_item_title">
                                                <img src={cItem.img} alt="" />
                                                <div className="container_comment_item_title_right">
                                                    <div className="container_comment_title_big">{cItem.title}</div>
                                                    <div className="container_comment_title_small
                                                    1">{cItem.title_small}</div>
                                                    <div className="container_comment_title_icon">{cItem.num}</div>
                                                </div>
                                            </div>
                                            <div className="container_comment_item_content">{cItem.content}</div>
                                        </div>
                                    </Swiper.Item>
                                })
                            }
                        </Swiper>
                    </Space>
                    {/* <ul className="container_comment_list">
                        {
                            commentList.map((item, index) => {
                                return <li className="container_comment_list_item" key={index}>
                                    <div className="container_comment_item_title">
                                        <img src={item.img} alt="" />
                                        <div className="container_comment_item_title_right">
                                            <div className="container_comment_title_big">{item.title}</div>
                                            <div className="container_comment_title_small
                                            1">{item.title_small}</div>
                                            <div className="container_comment_title_icon">{item.num}</div>
                                        </div>
                                    </div>
                                    <div className="container_comment_item_content">{item.content}</div>
                                </li>
                            })
                        }
                    </ul> */}
                </div>
                <div className="dashed_line"></div>
                <div className="container_form_box" id="contactUs">
                    <div className="container_form_box_right">
                        <div className="container_form_title">{t('contactUs.title1')}</div>
                        <div className="container_form_email">op@homescheckin.com</div>
                        <div className="container_form_introduce mb-30">{t('contactUs.introduce1')}</div>
                        <div className="dashed_line mb-30"></div>
                        <Form
                            form={form}
                            colon={false}
                            size="large"
                            layout="vertical"
                            onFinish={onFinish}
                            initialValues={{
                                ...formData
                            }}
                        >
                                {
                                    formList.map((item, index) => {
                                        return  <Form.Item
                                                    label={item.label + ' *'}
                                                    name={item.props}
                                                    key={index}
                                                    rules={item.rules}
                                                >
                                                    {
                                                        item.type === 'input'
                                                        ?   <Input placeholder={item.placeholder} />
                                                        :   <></>
                                                    }
                                                    {
                                                        item.type === 'textarea'
                                                        ?   <TextArea placeholder={item.placeholder} style={{ height: "120px", resize: "none" }} />
                                                        :   <></>
                                                    }
                                                </Form.Item>
                                    })
                                }
                                <Form.Item noStyle>
                                    <div style={{ paddingTop: "20px" }}>
                                        <Button type="primary" loading={loading} htmlType="submit" className="container_form_btn">{t('contactUs.btnName')}</Button>
                                    </div>
                                </Form.Item>
                        </Form>
                        <div className="container_form_bg" dangerouslySetInnerHTML={{ __html: t('contactUs.tooltip') }}></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUsPhone;